<template>
  <div class="cursor-icon">
    <svg viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M13.64,21.97C13.14,22.21 12.54,22 12.31,21.5L10.13,16.76L7.62,18.78C7.45,18.92 7.24,19 7,19A1,1 0 0,1 6,18V3A1,1 0 0,1 7,2C7.24,2 7.47,2.09 7.64,2.23L7.65,2.22L19.14,11.86C19.57,12.22 19.62,12.85 19.27,13.27C19.12,13.45 18.91,13.57 18.7,13.61L15.54,14.23L17.74,18.96C18,19.46 17.76,20.05 17.26,20.28L13.64,21.97Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Cursor',
}
</script>

<style lang="stylus" scoped>
@import '../css/globals.styl';

.cursor-icon {
  fill: $white;
}
</style>