// Portfolio single item
<template>
  <div class="item">
    <div class="item-header">
      <h6>{{ label }}</h6>
      <Cursor class="item-icon" />
    </div>
    <p class="item-text">
      {{ content }}
    </p>
  </div>
</template>

<script>
import Cursor from './Cursor.vue'

export default {
  name: 'PortfolioItem',
  components: { Cursor },
  props: {
    label: {
      type: String,
      required: true,
      default: '',
    },
    content: {
      type: String,
      required: true,
      default: '',
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../css/components/PortfolioItem.styl';
</style>