<template>
  <div class="page-content">
    <div class="portfolio-section">
      <h4>Portfolio</h4>
      <p>Click on a project below to see more information.</p>
      <div class="portfolio-grid">
        <NavLink
          v-for="(proj, index) in projects"
          :key="index"
          :is-external="proj.external"
          :link="proj.link"
          class="portfolio-item"
        >
          <PortfolioItem
            :label="proj.label"
            :content="proj.text"
          />
        </NavLink>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import PortfolioItem from '../components/PortfolioItem.vue'
import NavLink from '../components/NavLink.vue'


export default {
  name: 'Projects',
  components: {
    PortfolioItem,
    NavLink,
  },
  data () {
    return {
      reviews: [
        {
          name: 'the Site Admin',
          date: '04/10/2022',
          score: 0,
          text: 'Nothing here yet. Check back soon!',
        },
      ],
      projects: [],
    }
  },
  created () {
    axios({
      method: 'get',
      url: '/api/projects',
      headers: {
        'x-api-key': process.env.VUE_APP_APIKEY,
      },
    })
      .then((p) =>  {
        this.projects = [...p.data]
      })
  },
}
</script>

<style lang="stylus" scoped>
@import '../css/common.styl';
@import '../css/views/Projects.styl';
</style>
